import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Table, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import API_BASE_URL from "../apiConfig";

const FreeTrialProject = ({ user }) => {
    const [freeTrialProjects, setFreeTrialProjects] = useState([]);
    const [editedProject, setEditedProject] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Items per page


    const [searchTerm, setSearchTerm] = useState("");


    const filteredProjects = freeTrialProjects.filter((project) => {
        const id = project._id || ""; 
        const name = project.name || ""; 
        const email = project.userEmail || ""; 
      
        return (
          id.toLowerCase().includes(searchTerm.toLowerCase()) ||
          name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          email.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });


    useEffect(() => {
        fetchFreeTrialProjects();
    }, []);


    
    const fetchFreeTrialProjects = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/pixelStripeApi/projects`);
            if (response.ok) {
                const projectsData = await response.json();
                const projectsWithUserInfo = await Promise.all(projectsData.map(async project => {
                    const userResponse = await fetch(`${API_BASE_URL}/pixelStripeApi/users/${project.user}`);
                    if (userResponse.ok) {
                        const userData = await userResponse.json();
                        return {
                            ...project,
                            userEmail: userData && userData.email,
                            userName: userData && userData.name,
                            projectId: project._id,
                            projectName: project.name,
                            status: project.status,
                            subscriptionStatus: project.subscriptionStatus || '',
                            subscriptionStartDate: project.subscriptionStartDate || '',
                            subscriptionEndDate: project.subscriptionEndDate || '',
                        };
                    } else {
                        return project;
                    }
                }));

                const newData = projectsWithUserInfo.filter(e => e.subscriptionStatus === "authenticated" && e.appName !== undefined);
                setFreeTrialProjects(newData);
            } else {
                console.error('Failed to fetch projects data');
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };



    const handleEdit = (project) => {
        setEditedProject({ ...project });
        setEditMode(true);
    };

    const handleSave = async (projectId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editedProject),
            });

            if (response.ok) {
                alert('Project updated successfully');
                fetchFreeTrialProjects(); // Refresh the data
                setEditMode(false);
            } else {
                alert('Failed to update the project');
            }
        } catch (error) {
            console.error('Error saving project:', error);
        }
    };


    const handleEndFreeTrial = async (projectId) => {
        const requestData = {
            userId: user._id,
            webIp: process.env.REACT_APP_WEBIP,
            projectId: projectId,
        };
    
        try {
          const response = await axios.post(`${API_BASE_URL}/pixelStripeApi/projects/end-trial`, requestData);
    
          console.log('Response:', response.data);
          alert('Free trial ended successfully!');
    
        } catch (error) {
          // Handle error
          console.error('Error ending free trial:', error);
          alert('Failed to end free trial.');
        }
      };

 

    const handleStartSubscription = async (projectId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}/start-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                alert('Subscription started successfully');
                fetchFreeTrialProjects(); // Refresh the data
            } else {
                alert('Failed to start the subscription');
            }
        } catch (error) {
            console.error('Error starting subscription:', error);
        }
    };

    // Pagination controls
    const totalPages = Math.ceil(filteredProjects.length / itemsPerPage);

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(prevPage => prevPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(prevPage => prevPage + 1);
    };

    // Determine items to display on the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentProjects = filteredProjects.slice(startIndex, startIndex + itemsPerPage);

    const renderTableRows = () => {
        return currentProjects.map(project => (
            <tr key={project._id}>
                <td>{project._id}</td>
                <td>{project.projectName}</td>
                <td>{project.userName}</td>
                <td>
                    {editMode && editedProject._id === project._id ? (
                        <Form.Control
                            type="date"
                            value={editedProject.subscriptionStartDate}
                            onChange={(e) => setEditedProject({ ...editedProject, subscriptionStartDate: e.target.value })}
                        />
                    ) : (
                        project.subscriptionStartDate
                    )}
                </td>
                <td>{project.subscriptionEndDate}</td>
                <td>
                    {editMode && editedProject._id === project._id ? (
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            label={editedProject.status ? 'Active' : 'Inactive'}
                            checked={editedProject.status}
                            onChange={(e) => setEditedProject({ ...editedProject, status: e.target.checked })}
                        />
                    ) : (
                        project.status ? 'Active' : 'Inactive'
                    )}
                </td>
                <td>
                  
                    <Button variant="primary" onClick={() => handleEndFreeTrial(project._id)}>End Free Trial</Button>
                </td>
            </tr>
        ));
    };

    return (
        <Container>
            <Row>
                <Col xs={3}>
                    <h4>Ongoing Free Trial Projects</h4>
                </Col>

                <Col xs = {4}>
      <input
        type="text"
        placeholder="Search by name..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          marginBottom: "10px",
          padding: "8px",
          width: "300px",
          fontSize: "16px",
        }}
      />
                </Col>

                <Col md={{ span: 2, offset: 3 }}>
                    <div style={{ textAlign: 'end' }}>
                        <Button onClick={fetchFreeTrialProjects}>Refresh Data</Button>
                    </div>
                </Col>
            </Row>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ProjectId</th>
                        <th>Name</th>
                        <th>User</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTableRows()}
                </tbody>
            </Table>

            <Row className="justify-content-center mt-3">
                <Col xs="auto">
                    <Button 
                        variant="secondary" 
                        onClick={handlePreviousPage} 
                        disabled={currentPage === 1}
                    >
                        Previous
                    </Button>
                </Col>
                <Col xs="auto">
                    <span>Page {currentPage} of {totalPages}</span>
                </Col>
                <Col xs="auto">
                    <Button 
                        variant="secondary" 
                        onClick={handleNextPage} 
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default FreeTrialProject;
